
.contactSocieteImgContainer{
    position: relative;
    width: 330px;
    height: 135px;
}

.contactSocieteImgContainer .divWrapperImgResizer img{
    position: unset !important;
}

.contactSocieteImgContainer .divWrapperImgResizer{
    position: absolute !important;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.contactSocieteImgContainer img{
    position: relative !important;
}

.contactSocieteImgContainer div{
    position: absolute !important;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}