.graphOJDTitle{
    text-align: center;
    margin-bottom: 0px;
}

.graphOJDImg{
    width: 50px;
    top: 5px;
    left : 5px;
    float: left;
}

.graphOJDMonthTitle{
    margin-bottom: 0px;
    text-align: center
}