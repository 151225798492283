.graphOJDMensTitle{
    position: absolute;
    left: 45px;
    font-size: 12px;
}

.graphOJDMensContainer{
 
}

.row{
    position: relative;
}

.sunburstContainer{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.rv-discrete-color-legend.vertical {
    width: auto !important;
    height: auto !important;
} 