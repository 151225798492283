

.vertical-timeline.vertical-timeline--animate{

}

.vertical-timeline {
    padding: 2px 0;
    margin: auto;
    width:350px;
    max-width: 350px;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline {
        padding: 2px 0;
        margin: auto;
        width:350px;
        max-width: 350px;
    }
    .vertical-timeline:before {

      left: 90%;
      background:  rgba(0,0,0,0.03);
    }
  }


.vertical-timeline-element-content{
    width: 85%;
}

.vertical-timeline-element-content.bounce-in, .vertical-timeline-element-content.is-hidden{
    padding: 0px;
    background: rgba(0,0,0,0.03);
    margin: 0px;
}

.vertical-timeline-element--work.vertical-timeline-element.vertical-timeline-element--left{
    margin:5px;
}

.vertical-timeline-element--month.vertical-timeline-element.vertical-timeline-element--left{
    margin:5px;
}

.vertical-timeline-element--paru.vertical-timeline-element.vertical-timeline-element--left{
    margin:5px;
    margin-left: 50px;
    margin-right: 10px;
}

.vertical-timeline-element-content.is-hidden p, .vertical-timeline-element-content.bounce-in p{
    margin: 0px;
    width: 85%;
    margin-left: 10px;
}
.vertical-timeline-element-content.is-hidden h3, .vertical-timeline-element-content.bounce-in h3{
    padding: 15px;
    margin: 0px;
}


.vertical-timeline-element-content.bounce-in::before, .vertical-timeline-element-content.is-hidden::before{
    border-left-color :  rgba(0,0,0,0.03);
    top: 0px;  @media screen {
        top:0px;
    }
    
}

.vertical-timeline-element-icon{
    left: 90%;
    margin-left: 2px;
}

.vertical-timeline:before{
    left: 90%;
    background:  rgba(0,0,0,0.03);
}

.vertical-timeline-element--paru
{
    
}