.rv-discrete-color-legend-item.vertical{
    padding-bottom: 0px;
}

.rv-xy-plot.verticalbarseriesexample{
    margin: auto
}

.rv-discrete-color-legend-item__title{
    margin-left: 10px !important;
}
