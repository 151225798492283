svg{
    overflow: visible
}

.legendLeft{
    position: absolute;
    left: 5px;
    font-size: 12px;
    top: 5px;
}

.legendRight{
    position: absolute;
    right: 10px;
    font-size: 12px;
    top: 5px;
}

.legendLeftNoTitle{
    position: absolute;
    left: 5px;
    font-size: 12px;
}

.legendRightNoTitle{
    position: absolute;
    right: 10px;
    font-size: 12px;
}