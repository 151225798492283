.vertical-timeline-element {
    position: relative;
    margin: 2em 0;
  }
  .vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }

    .vertical-timeline-element {
      margin: 4em 0;
    }
    .vertical-timeline-element:first-child {
      margin-top: 0;
    }
    .vertical-timeline-element:last-child {
      margin-bottom: 0;
    }
  
  
  .vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  .vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }
  

    .vertical-timeline-element-icon {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
    }
  
  
  /* Icon animations */
  .vertical-timeline-element-icon {
    /* Force Hardware Acceleration in WebKit */

  }

  .vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
    visibility: visible;
  }
  
  .vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: white;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 3px 0 #ddd;
  }
  .vertical-timeline-element--no-children .vertical-timeline-element-content {
    background: transparent;
    box-shadow: none;
  }
  .vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
  }
  .vertical-timeline-element-content h2 {
    color: #303e49;
  }
  .vertical-timeline-element-content p, .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #333;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    display: inline-block;
  }
  .vertical-timeline-element-content p {
    margin: 1em 0 0;
    line-height: 1.6;
  }
  
  .vertical-timeline-element-title {
    margin: 0;
  }
  
  .vertical-timeline-element-subtitle {
    margin: 0;
  }
  
  .vertical-timeline-element-content .vertical-timeline-element-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
  }
  .vertical-timeline-element-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid white;
  }
  .vertical-timeline-element--no-children .vertical-timeline-element-content::before {
    display: none;
  }
 
    .vertical-timeline-element-content h2 {
      font-size: 20px;
      font-size: 1.25rem;
    }
    .vertical-timeline-element-content p {
      font-size: 16px;
      font-size: 1rem;
    }
    .vertical-timeline-element-content .vertical-timeline-element-date {
      font-size: 14px;
      font-size: 0.875rem;
    }
  
  
    .vertical-timeline-element-content {
      margin-left: 0;
      padding: 1.5em;
      width: 40%;
    }
    .vertical-timeline-element-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white;
    }
  
    .vertical-timeline-element-content .vertical-timeline-element-date {
      position: absolute;
      width: 100%;
      left: 124%;
      top: 6px;
      font-size: 16px;
      font-size: 1rem;
    }
  
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content,
    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content {
      float: right;
    }
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before,
    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: white;
    }
  
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date,
    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date {
      left: auto;
      right: 124%;
      text-align: right;
    }
  
  
  /* Box animations */
  .vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
    visibility: visible;
  }
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;

  }
  

    /* inverse bounce effect on even content blocks */
    .vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in,
    .vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in {

    }