
.verticalGraphMap{
    float: right;
    position: relative
}

.configurationContent table th {
    width: 120px;
}


  .configurationContent{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px 0px;
    padding: 5px;

    margin-top: 15%;
    font-size: 12px;
    font-weight: bold;
  }

  .imageojdBlockMap{
      position: absolute;
      left: 5px;
      top : 40px
 }

.rw-combobox.rw-widget{
     z-index: 1000;
}