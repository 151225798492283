.infogeneralesDiv{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.infogeneralesDiv h2{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
}

.infogeneralestab th {
    width: 150px;
}