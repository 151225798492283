
.textDaysNextParu{
    font-size: 14px;
    margin-left: 10px
}

.textDateNextParu{
    font-size: 14px;
    margin-left: 10px;
    font-weight: bolder
}

.ficheNextParution{
    margin-top: 10px;
}

.nextParuNumber{

    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    color: white;
    font-weight: bolder;
    font-size: 30px;
}

.strokeBottomParution{
    height: 2px;
    margin-top: 16px;
    background: rgb(140,38,205);
}