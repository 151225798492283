.verticalGraphColumn{
    float: right;
    margin-right: 30px;
    position: relative
}

.blockAudienceTableOJDContainer{
    float: left;
	width: 100%;
    margin-right: -770px
}

.blockAudienceTableOJDContent{
    margin-left: 10px;
    margin-right: 770px;
}

.blockAudienceAllGraphs{
    float: left;
    width: 770px;
}

.blockAudienceTableOJD{
    padding-top: 10px;
}

.subComponentOjdMonth{
    margin-left: 50px;
    margin-bottom: 25px;
    margin-top: 10px;
}