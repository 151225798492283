
.infosParutions{
    padding: 5px;
    width: 350px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.infosParutions h3{
    margin: 5px;
}

.cellDayOfWeek{
    float: left;
    width: 60px;
}

.cellDayOfMonth{
    float: left;
    width: 20px;
}

.cellMonth{
    float: left;
    width: 65px;
}
.cellYear{
    float: left;
    width: 25px;
}

.rt-td{
     margin-left: 0px !important;
}

.basicParutionCell{
    height:14px;
    overflow:hidden
}

.currentParuBuble{
    background: red;
    border-radius: 5px;
    color: white !important;
}

.currentParuBuble p{
    color: white !important;
}

.currentParutionCell{
    padding-top: 5px;
    background: bisque;
    height: 24px;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    color: white;
    font-weight: bolder;
}

.rt-tr.-even {
    height: 24px;
}